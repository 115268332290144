import React from 'react'
import './whatGPT3.css'
import { Feature } from '../../components'

const WhatGPT3 = () => {
  return (
   <div className="gpt3__whatgpt3 section__padding" id='wgpt3'>
    <div className="gpt3__whatgpt3-feature">
      <Feature title='what is gpt3?' text= 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur rutrum nibh quam, tincidunt lobortis ex ullamcorper et . ' />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className='gradient__text'>The Possibilities are beyond your imagination</h1>
      <p>Explore the library</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title='ChatBots' text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."/>
      <Feature title="Knowledgebase" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur rutrum nibh quam, tincidunt lobortis ex ullamcorper et. "/>
      <Feature title="Education" text='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'/>
    </div>
   </div>
  )
}

export default WhatGPT3